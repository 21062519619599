// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-digital-product-design-desktop-software-index-js": () => import("./../../../src/pages/app/digital-product-design/desktop-software/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-desktop-software-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-advanced-features-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/advanced-features/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-advanced-features-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-complex-animations-and-interactions-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/complex-animations-and-interactions/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-complex-animations-and-interactions-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-design-from-digital-wireframes-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/design-from-digital-wireframes/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-design-from-digital-wireframes-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-design-from-paper-wireframes-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/design-from-paper-wireframes/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-design-from-paper-wireframes-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-design-from-scratch-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/design-from-scratch/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-design-from-scratch-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-design-from-sketch-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/design-from-sketch/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-design-from-sketch-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-how-do-you-want-to-work-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/how-do-you-want-to-work/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-how-do-you-want-to-work-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-i-am-not-a-designer-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/I-am-not-a-designer/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-i-am-not-a-designer-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-interactive-prototype-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/interactive-prototype/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-interactive-prototype-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-operating-system-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/operating-system/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-operating-system-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-static-design-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/static-design/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-static-design-index-js" */),
  "component---src-pages-app-digital-product-design-hi-fi-what-is-your-goal-index-js": () => import("./../../../src/pages/app/digital-product-design/hi-fi/what-is-your-goal/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-hi-fi-what-is-your-goal-index-js" */),
  "component---src-pages-app-digital-product-design-index-js": () => import("./../../../src/pages/app/digital-product-design/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-index-js" */),
  "component---src-pages-app-digital-product-design-lo-fi-design-from-scratch-index-js": () => import("./../../../src/pages/app/digital-product-design/lo-fi/design-from-scratch/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-lo-fi-design-from-scratch-index-js" */),
  "component---src-pages-app-digital-product-design-lo-fi-design-from-wireframes-index-js": () => import("./../../../src/pages/app/digital-product-design/lo-fi/design-from-wireframes/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-lo-fi-design-from-wireframes-index-js" */),
  "component---src-pages-app-digital-product-design-lo-fi-how-do-you-want-to-work-index-js": () => import("./../../../src/pages/app/digital-product-design/lo-fi/how-do-you-want-to-work/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-lo-fi-how-do-you-want-to-work-index-js" */),
  "component---src-pages-app-digital-product-design-lo-fi-idea-testing-index-js": () => import("./../../../src/pages/app/digital-product-design/lo-fi/idea-testing/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-lo-fi-idea-testing-index-js" */),
  "component---src-pages-app-digital-product-design-lo-fi-index-js": () => import("./../../../src/pages/app/digital-product-design/lo-fi/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-lo-fi-index-js" */),
  "component---src-pages-app-digital-product-design-lo-fi-operating-system-index-js": () => import("./../../../src/pages/app/digital-product-design/lo-fi/operating-system/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-lo-fi-operating-system-index-js" */),
  "component---src-pages-app-digital-product-design-lo-fi-rapid-prototyping-index-js": () => import("./../../../src/pages/app/digital-product-design/lo-fi/rapid-prototyping/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-lo-fi-rapid-prototyping-index-js" */),
  "component---src-pages-app-digital-product-design-mobile-app-index-js": () => import("./../../../src/pages/app/digital-product-design/mobile-app/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-mobile-app-index-js" */),
  "component---src-pages-app-digital-product-design-web-app-index-js": () => import("./../../../src/pages/app/digital-product-design/web-app/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-web-app-index-js" */),
  "component---src-pages-app-digital-product-design-website-index-js": () => import("./../../../src/pages/app/digital-product-design/website/index.js" /* webpackChunkName: "component---src-pages-app-digital-product-design-website-index-js" */),
  "component---src-pages-app-graphic-design-index-js": () => import("./../../../src/pages/app/graphic-design/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-index-js" */),
  "component---src-pages-app-graphic-design-print-products-have-you-used-a-design-tool-before-index-js": () => import("./../../../src/pages/app/graphic-design/print-products/have-you-used-a-design-tool-before/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-print-products-have-you-used-a-design-tool-before-index-js" */),
  "component---src-pages-app-graphic-design-print-products-index-js": () => import("./../../../src/pages/app/graphic-design/print-products/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-print-products-index-js" */),
  "component---src-pages-app-graphic-design-slides-index-js": () => import("./../../../src/pages/app/graphic-design/slides/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-slides-index-js" */),
  "component---src-pages-app-graphic-design-slides-online-tool-index-js": () => import("./../../../src/pages/app/graphic-design/slides/online-tool/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-slides-online-tool-index-js" */),
  "component---src-pages-app-graphic-design-slides-operating-system-index-js": () => import("./../../../src/pages/app/graphic-design/slides/operating-system/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-slides-operating-system-index-js" */),
  "component---src-pages-app-graphic-design-social-media-posts-have-you-used-a-design-tool-before-index-js": () => import("./../../../src/pages/app/graphic-design/social-media-posts/have-you-used-a-design-tool-before/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-social-media-posts-have-you-used-a-design-tool-before-index-js" */),
  "component---src-pages-app-graphic-design-social-media-posts-index-js": () => import("./../../../src/pages/app/graphic-design/social-media-posts/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-social-media-posts-index-js" */),
  "component---src-pages-app-graphic-design-vector-graphics-have-you-used-a-design-tool-before-index-js": () => import("./../../../src/pages/app/graphic-design/vector-graphics/have-you-used-a-design-tool-before/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-vector-graphics-have-you-used-a-design-tool-before-index-js" */),
  "component---src-pages-app-graphic-design-vector-graphics-index-js": () => import("./../../../src/pages/app/graphic-design/vector-graphics/index.js" /* webpackChunkName: "component---src-pages-app-graphic-design-vector-graphics-index-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-tool-adobe-illustrator-index-js": () => import("./../../../src/pages/app/tool/adobe-illustrator/index.js" /* webpackChunkName: "component---src-pages-app-tool-adobe-illustrator-index-js" */),
  "component---src-pages-app-tool-adobe-indesign-index-js": () => import("./../../../src/pages/app/tool/adobe-indesign/index.js" /* webpackChunkName: "component---src-pages-app-tool-adobe-indesign-index-js" */),
  "component---src-pages-app-tool-adobe-photoshop-index-js": () => import("./../../../src/pages/app/tool/adobe-photoshop/index.js" /* webpackChunkName: "component---src-pages-app-tool-adobe-photoshop-index-js" */),
  "component---src-pages-app-tool-adobe-xd-index-js": () => import("./../../../src/pages/app/tool/adobe-xd/index.js" /* webpackChunkName: "component---src-pages-app-tool-adobe-xd-index-js" */),
  "component---src-pages-app-tool-apple-keynote-index-js": () => import("./../../../src/pages/app/tool/apple-keynote/index.js" /* webpackChunkName: "component---src-pages-app-tool-apple-keynote-index-js" */),
  "component---src-pages-app-tool-canva-index-js": () => import("./../../../src/pages/app/tool/canva/index.js" /* webpackChunkName: "component---src-pages-app-tool-canva-index-js" */),
  "component---src-pages-app-tool-figma-index-js": () => import("./../../../src/pages/app/tool/figma/index.js" /* webpackChunkName: "component---src-pages-app-tool-figma-index-js" */),
  "component---src-pages-app-tool-framer-index-js": () => import("./../../../src/pages/app/tool/framer/index.js" /* webpackChunkName: "component---src-pages-app-tool-framer-index-js" */),
  "component---src-pages-app-tool-invision-index-js": () => import("./../../../src/pages/app/tool/invision/index.js" /* webpackChunkName: "component---src-pages-app-tool-invision-index-js" */),
  "component---src-pages-app-tool-microsoft-powerpoint-index-js": () => import("./../../../src/pages/app/tool/microsoft-powerpoint/index.js" /* webpackChunkName: "component---src-pages-app-tool-microsoft-powerpoint-index-js" */),
  "component---src-pages-app-tool-miro-or-mural-index-js": () => import("./../../../src/pages/app/tool/miro-or-mural/index.js" /* webpackChunkName: "component---src-pages-app-tool-miro-or-mural-index-js" */),
  "component---src-pages-app-tool-pitch-or-google-slides-index-js": () => import("./../../../src/pages/app/tool/pitch-or-google-slides/index.js" /* webpackChunkName: "component---src-pages-app-tool-pitch-or-google-slides-index-js" */),
  "component---src-pages-app-tool-principle-index-js": () => import("./../../../src/pages/app/tool/principle/index.js" /* webpackChunkName: "component---src-pages-app-tool-principle-index-js" */),
  "component---src-pages-app-tool-protopie-index-js": () => import("./../../../src/pages/app/tool/protopie/index.js" /* webpackChunkName: "component---src-pages-app-tool-protopie-index-js" */),
  "component---src-pages-app-tool-sketch-index-js": () => import("./../../../src/pages/app/tool/sketch/index.js" /* webpackChunkName: "component---src-pages-app-tool-sketch-index-js" */),
  "component---src-pages-app-tool-uizard-index-js": () => import("./../../../src/pages/app/tool/uizard/index.js" /* webpackChunkName: "component---src-pages-app-tool-uizard-index-js" */),
  "component---src-pages-best-choice-for-you-index-js": () => import("./../../../src/pages/best-choice-for-you/index.js" /* webpackChunkName: "component---src-pages-best-choice-for-you-index-js" */),
  "component---src-pages-best-for-ui-ux-index-js": () => import("./../../../src/pages/best-for-ui-ux/index.js" /* webpackChunkName: "component---src-pages-best-for-ui-ux-index-js" */),
  "component---src-pages-figma-alternative-index-js": () => import("./../../../src/pages/figma-alternative/index.js" /* webpackChunkName: "component---src-pages-figma-alternative-index-js" */),
  "component---src-pages-guides-index-js": () => import("./../../../src/pages/guides/index.js" /* webpackChunkName: "component---src-pages-guides-index-js" */),
  "component---src-pages-highlight-index-js": () => import("./../../../src/pages/highlight/index.js" /* webpackChunkName: "component---src-pages-highlight-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

